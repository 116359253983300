import { DetailsList, DetailsListLayoutMode, IColumn, IDetailsColumnStyles, SelectionMode } from "@fluentui/react";
import React from "react";
import _ from "lodash";
import { SurveyQuestion } from "src/types/surveyTypes";
import { Text } from "@fluentui/react";

type LikertProps = {
  question: SurveyQuestion;
  onChoice: (question: SurveyQuestion, answerText?: string, answerId?: string, parentQuestionId?: string) => void;
};

const headerStyles: Partial<IDetailsColumnStyles> = {
  cellTitle: {
    fontWeight: 400,
  },
  cellName: {
    fontWeight: 400,
  },
};

export const LikertComponent = (props: LikertProps) => {
  const { question, onChoice } = props;

  let rows: any[] = [];
  let columns: IColumn[] = [];

  const onChange = (event: any) => {
    const qId = event.target.name;
    const aId = event.target.id;
    const at = event.target.value;
    const q = question.subQuestions?.find((x) => x.id === qId);
    if (!!q) {
      onChoice(q, at, aId, question.id);
    }
  };

  if (!!question.subQuestions && !_.isEmpty(question.subQuestions)) {
    question.subQuestions.forEach((q) => {
      let row: any = {
        id: q.id,
        questionText: q.text,
      };
      q.answers.forEach((a, idx) => {
        row[`optionId${idx + 1}`] = a.id;
        row[`optionText${idx + 1}`] = a.text;
      });
      rows.push(row);
    });

    columns.push({
      key: "column1",
      name: "",
      fieldName: "questionText",
      isMultiline: true,
      minWidth: 100,
      maxWidth: 200,
      onRender: (item) => <Text variant={"medium"}>{item.questionText}</Text>,
    });

    // pick the first sub question, all questions would ideally have same number of options.
    question.subQuestions[0].answers.forEach((a, idx) => {
      columns.push({
        key: `column${idx + 2}`,
        name: a.text,
        fieldName: `optionText${idx + 1}`,
        minWidth: 80,
        maxWidth: 120,
        styles: headerStyles,
        onRender: (item) => <input type="radio" name={item.id} id={a.id} value={a.text} onChange={onChange} />,
      });
    });
  }

  return (
    <div style={{ width: "100%" }}>
      <DetailsList items={rows} columns={columns} layoutMode={DetailsListLayoutMode.justified} selectionMode={SelectionMode.none} />
    </div>
  );
};
