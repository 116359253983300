import { AppEnvironment } from "src/types/configTypes";
import { Survey, SurveyResponse } from "src/types/surveyTypes";

export type DataStore = {
  requestId: string;
  survey: Survey;
  surveyResponse: SurveyResponse;
  appConfig: AppEnvironment;
};

export const initialDataStore: DataStore = {
  requestId: "",
  survey: {
    id: "",
    typeId: -1,
    type: "",
    workshopTypeId: -1,
    workshopKind: "",
    programTypeId: -1,
    programKind: "",
    name: "",
    displayName: "",
    description: "",
    questions: [],
  },
  surveyResponse: {
    requestId: "",
    surveyId: "",
    surveyTypeId: -1,
    programTypeId: -1,
    workshopTypeId: -1,
    userResponse: [],
  },
  appConfig: {
    env: "",
    apiBaseUrl: "",
    appInsightsKey: "",
  },
};
