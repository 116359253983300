import { MessageBar, MessageBarType, TextField } from "@fluentui/react";
import React, { useState } from "react";
import { SurveyQuestion } from "src/types/surveyTypes";
import { validationService } from "src/services/validationService";

type TextAnswerProps = {
  question: SurveyQuestion;
  onChoice: (question: SurveyQuestion, answerText?: string) => void;
};

export const TextAnswerComponent = (props: TextAnswerProps) => {
  const { question, onChoice } = props;

  const [showValidationMessage, setShowValidationMessage] = useState<boolean>(false);
  const [validationMessage, setValidationMessage] = useState<string>("");

  const isLargeText = question.type === "LargeText";

  const onChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
    if (!!newValue) {
      const validationResult = validationService.validateText(question, newValue);
      onChoice(question, newValue);
      setShowValidationMessage(!validationResult.valid);
      setValidationMessage(validationResult.message);
    } else {
      onChoice(question);
    }
  };

  return (
    <div style={{ width: "100%" }}>
      {isLargeText && <TextField onChange={onChange} multiline autoAdjustHeight styles={{ root: { fontSize: "16px" } }} />}
      {!isLargeText && <TextField onChange={onChange} styles={{ root: { fontSize: "16px" } }} />}
      {showValidationMessage && <MessageBar messageBarType={MessageBarType.error}>{validationMessage}</MessageBar>}
    </div>
  );
};
