import { FontIcon, Stack } from "@fluentui/react";
import React from "react";
import { Text } from "@fluentui/react";
import styled from "@emotion/styled";
import { getAppHeader } from "src/services/utils";
import { useDataStoreContext } from "src/store/dataStoreContext";

const ErrorHeaderTextContainer = styled(Stack)`
  background-color: #0078d4;
  color: #ffffff;
  width: 100%;
`;

const ErrorHeaderText = styled(Text)`
  color: #ffffff;
`;

const ErrorText = styled(Text)`
  align-self: center;
  text-align: center;
`;

export const ErrorComponent = (props: { message: string }) => {
  const { message } = props;

  const { dataStore } = useDataStoreContext();
  const { survey } = dataStore;

  const headerText = getAppHeader(survey.programKind);

  return (
    <Stack style={{ width: "100%" }} tokens={{ childrenGap: 48 }}>
      <ErrorHeaderTextContainer horizontalAlign="center" tokens={{ childrenGap: 16, padding: 8 }}>
        <ErrorHeaderText variant={"xxLarge"}>{headerText}</ErrorHeaderText>
      </ErrorHeaderTextContainer>
      <ErrorText variant="xxLarge">
        {message}
      </ErrorText>
      <FontIcon iconName="StatusErrorFull" style={{ alignSelf: "center", fontSize: 50, color: "#c2c2c2" }} />
    </Stack>
  );
};
