import { APP_HEADER_TEXT, SPECIAL_INVESTMENTS_APP_HEADER_TEXT, EVENTS_APP_HEADER_TEXT } from "src/constants";
import { ProgramKind } from "src/types/surveyTypes";

export const getAppHeader = (programKind: ProgramKind | string) => {
  switch (programKind) {
    case "PartnerCenter":
      return APP_HEADER_TEXT;
    case "MSSPPartnerProgram":
    case "OperatorProgram":
      return SPECIAL_INVESTMENTS_APP_HEADER_TEXT;
    case "MCAPEvents":
      return EVENTS_APP_HEADER_TEXT;
    default:
      return APP_HEADER_TEXT;
  }
};
