import React from "react";
import { Route, Switch, HashRouter } from "react-router-dom";
import { HomePage } from "src/pages/HomePage";
import { initialDataStore } from "src/store/dataStore";
import { DataStoreReducer } from "src/store/dataStoreReducer";
import { DataStoreContext } from "src/store/dataStoreContext";
import { ThankyouPage } from "src/pages/ThankyouPage";
import { ErrorPage } from "src/pages/ErrorPage";
import MsFooter from "./UhfFooter/uhfFooter";
import MsHeader from "./UhfFooter/uhfHeader";

export const App = () => {
  const [dataStore, dataStoreDispatch] = React.useReducer(DataStoreReducer, initialDataStore);
  return (
    <DataStoreContext.Provider value={{ dataStoreDispatch, dataStore }}>
      <div className="app-container flex flex-column">
        <HashRouter>
          <div className="uhf-header-main ul.f-multi-column">
            <MsHeader />
          </div>
          <Switch>
            <Route path="/:programType/:surveyType/:workshopType/:requestId" component={HomePage} exact={true} />
            <Route path="/:programType/:surveyType/:workshopType" component={HomePage} exact={true} />
            <Route path="/thank-you" component={ThankyouPage} exact={true} />
            <Route path="/*" component={ErrorPage} exact={true} />
          </Switch>
        </HashRouter>
      </div>
      <MsFooter />
    </DataStoreContext.Provider>
  );
};
