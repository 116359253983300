import { ApplicationInsights, IApplicationInsights } from "@microsoft/applicationinsights-web";
import { createBrowserHistory } from "history";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { TelemetryEventName } from "src/telemetry/telemetryEvents";

const browserHistory = createBrowserHistory();

const reactPlugin = new ReactPlugin();

let ai: IApplicationInsights;

export const loadAppInsights = (instrumentationKey: string) => {
  const appInsights = new ApplicationInsights({
    config: {
      instrumentationKey: instrumentationKey,
      extensions: [reactPlugin],
      extensionConfig: {
        [reactPlugin.identifier]: { history: browserHistory },
      },
    },
  });

  ai = appInsights.loadAppInsights();
};

export const tractEvent = (name: TelemetryEventName, properties: any) => {
  ai.appInsights.trackEvent({ name, properties });
};

export const trackError = (error: any, errorInfo: any) => {
  const exception = {
    error: error,
    errotInfo: errorInfo,
  };
  ai.trackException(exception);
};
