import { ChoiceGroup, IChoiceGroupOption } from "@fluentui/react";
import React from "react";
import { SurveyQuestion } from "src/types/surveyTypes";

type ChoiceProps = {
  question: SurveyQuestion;
  onChoice: (question: SurveyQuestion, answerText?: string, answerId?: string) => void;
};

export const SingleChoiceComponent = (props: ChoiceProps) => {
  const { question, onChoice } = props;
  const { answers } = question;

  const options: IChoiceGroupOption[] = answers.map((a) => {
    return {
      key: a.id,
      text: a.text,
      styles: { root: { fontSize: "14px" } },
    };
  });

  const onChange = (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, option?: IChoiceGroupOption) => {
    if (!!option) {
      onChoice(question, option.text, option.key);
    } else {
      onChoice(question);
    }
  };

  return <ChoiceGroup options={options} onChange={onChange} />;
};
