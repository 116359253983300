import { SurveyComponent } from "src/components/SurveyComponent";
import styled from "@emotion/styled";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useDataStoreContext } from "src/store/dataStoreContext";
import React, { useEffect, useState } from "react";
import { surveyService } from "src/services/surveyService";
import { DefaultButton, Dialog, DialogFooter, IDialogContentProps, PrimaryButton, Stack, Link } from "@fluentui/react";
import { Text } from "@fluentui/react";
import { ErrorComponent } from "src/components/ErrorComponent";
import { UrlParamTypes } from "src/types/urlParamTypes";
import { API_ERROR_MESSAGE, INVALID_URL_PARAMS_ERROR, RETAKE_SURVEY_WARNING } from "src/constants";
import _ from "lodash";
import { appConfigService } from "src/core/services/appConfigService";
import { loadAppInsights } from "src/telemetry/appInsights";
import { SpinnerComponent } from "src/core/components/SpinnerComponent";
import { PartnerSurveyFooterColor, CustomerSurveyFooterColor } from "src/core/colors";

const Root = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SurveyRoot = styled.div`
  display: grid;
  grid-template-columns: 1fr minmax(400px, 850px) 1fr;
  grid-template-rows: 48px auto auto;
  width: 100%;
  background-color: #f8f8f8;
  align-self: start;
`;

const Header = styled.div`
  grid-row-start: 1;
  grid-row-end: 1;
  grid-column-start: 1;
  grid-column-end: 4;
  background-color: #f8f8f8;
  height: 48px;
`;

const CenterColumn = styled.div`
  grid-row-start: 2;
  grid-row-end: 2;
  grid-column-start: 2;
  grid-column-end: 2;
  display: flex;
  justify-content: center;
  align-content: start;
  background-color: #ffffff;
  padding-bottom: 8px;
  height: fit-content;
`;

const Footer = styled.div`
  grid-row-start: 3;
  grid-row-end: 3;
  grid-column-start: 2;
  grid-column-end: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 84px;
  margin-top: 16px;
  margin-bottom: 16px;
  padding-left: 20px;
  padding-right: 20px;
`;

const SmallTextLink = styled(Link)`
  font-size: 12px;
`;
export const HomePage = () => {
  const { dataStore, dataStoreDispatch } = useDataStoreContext();
  const { survey } = dataStore;
  const { programType, surveyType, workshopType, requestId } = useParams<UrlParamTypes>();
  let location = useLocation();

  const [showProgress, setShowProgres] = useState(false);
  const [hasSurvey, setHasSurvey] = useState(false);
  const [hasWarning, setHasWarning] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [validationMessage, setValidationMessage] = useState(API_ERROR_MESSAGE);

  const history = useHistory();

  const useQuery = () => {
    return new URLSearchParams(location.search);
  };

  const requestIdFromQuery = useQuery().get("id");
  const svfr = useQuery().get("svfr");
  const skipValidationForRegistration = !!svfr ? svfr.toLocaleLowerCase() === "true" : false;

  const getSurvey = async () => {
    setShowProgres(true);
    const response = await surveyService.getSurvey(programType, surveyType, workshopType);
    const hasData = !response.hasError;
    setHasSurvey(hasData);
    if (hasData)
      dataStoreDispatch({
        kind: "SET_SURVEY_QUESTIONS",
        payload: response.data,
      });
    setShowProgres(false);
  };

  const continueOnWarning = () => {
    setHasWarning(false);
    getSurvey();
  };

  const cancelOnWarning = () => {
    setHasWarning(false);
    history.push({ pathname: "/thank-you", state: { message: "Thank you" } });
  };

  const id = !_.isEmpty(requestId) ? requestId : requestIdFromQuery;

  useEffect(() => {
    if (!!id && id.length > 0) {
      dataStoreDispatch({
        kind: "SET_REQUEST_ID",
        payload: id,
      });

      (async () => {
        setShowProgres(true);
        await loadAppConfig();
        const surveyValidationResponse = await surveyService.validateSurvey(
          programType,
          surveyType,
          workshopType,
          id,
          skipValidationForRegistration
        );
        const validationResponse = surveyValidationResponse.data;
        const isValid = !surveyValidationResponse.hasError && validationResponse.result !== "Error";

        if (isValid) {
          if (validationResponse.result === "Success") {
            await getSurvey();
          } else if (validationResponse.result === "Warning") {
            setHasWarning(true);
          }
        } else {
          setHasError(true);
        }
        setValidationMessage(validationResponse.message);
        setShowProgres(false);
      })();
    } else {
      setHasError(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadAppConfig = async () => {
    const config = await appConfigService.getAppEnvironmentData();
    dataStoreDispatch({
      kind: "SET_APP_CONFIG",
      payload: config,
    });

    loadAppInsights(config.appInsightsKey);
  };

  const dialogContentProps: IDialogContentProps = {
    title: "Retake Survey?",
    subText: !!validationMessage ? validationMessage : RETAKE_SURVEY_WARNING,
  };

  const footerBackground = survey.type === "Customer" ? CustomerSurveyFooterColor : PartnerSurveyFooterColor;

  return (
    <Root>
      {showProgress && <SpinnerComponent size="large" />}
      {hasWarning && (
        <Dialog hidden={false} onDismiss={cancelOnWarning} dialogContentProps={dialogContentProps} modalProps={{ isBlocking: true }}>
          <DialogFooter>
            <PrimaryButton onClick={continueOnWarning} text="Continue" />
            <DefaultButton onClick={cancelOnWarning} text="Cancel" />
          </DialogFooter>
        </Dialog>
      )}
      {!showProgress && (
        <>
          <SurveyRoot>
            <Header />
            <CenterColumn>
              {hasSurvey && <SurveyComponent />}
              {!hasSurvey && hasError && <ErrorComponent message={!!validationMessage ? validationMessage : INVALID_URL_PARAMS_ERROR} />}
            </CenterColumn>
            {hasSurvey && (
              <Footer style={{ background: footerBackground }}>
                <Stack tokens={{ childrenGap: 16 }}>
                  <Text variant={"small"}>
                    This content is created by the owner of the form. The data you submit will be sent to the form owner. Microsoft is not
                    responsible for the privacy or security practices of its customers, including those of this form owner. Never give out
                    your password.
                  </Text>
                  <Stack horizontal tokens={{ childrenGap: 8 }}>
                    <SmallTextLink href="https://privacy.microsoft.com/en-us/privacystatement" target="_blank">
                      Privacy and cookies
                    </SmallTextLink>
                    <Text variant={"small"}>|</Text>
                    <SmallTextLink href="https://www.microsoft.com/en-us/servicesagreement/default.aspx" target="_blank">
                      Terms of use
                    </SmallTextLink>
                  </Stack>
                </Stack>
              </Footer>
            )}
          </SurveyRoot>
        </>
      )}
    </Root>
  );
};
