import styled from "@emotion/styled";
import { Rating } from "@fluentui/react";
import React from "react";
import { SurveyQuestion } from "src/types/surveyTypes";

type RatingProps = {
  question: SurveyQuestion;
  onChoice: (question: SurveyQuestion, answerText?: string) => void;
};

const Root = styled.div`
  display: flex;
  flex-direction: row;
  & > *:not(div:first-of-type) {
    margin-left: 8px;
  }
`;

export const RatingComponent = (props: RatingProps) => {
  const { question, onChoice } = props;

  const onChange = (event: React.FormEvent<HTMLElement>, rating?: number) => {
    if (!!rating) {
      onChoice(question, rating.toString());
    } else {
      onChoice(question);
    }
  };

  return (
    <Root>
      <Rating allowZeroStars max={5} onChange={onChange}></Rating>
    </Root>
  );
};
