import React from "react";
import { Spinner } from "@fluentui/react";

type SpinnerComponentSize = "large" | "medium" | "small" | "xSmall";

type SpinnerComponentProps = {
  height?: number;
  width?: number;
  borderWidth?: number;
  size?: SpinnerComponentSize;
};

const getSpinnerDimensions = (size: SpinnerComponentSize) => {
  let h = 60;
  let w = 60;
  let bw = 6;
  switch (size) {
    case "large":
      h = 80;
      w = 80;
      bw = 8;
      break;
    case "medium":
      h = 60;
      w = 60;
      bw = 6;
      break;
    case "small":
      h = 40;
      w = 40;
      bw = 4;
      break;
    case "xSmall":
      h = 20;
      w = 20;
      bw = 2;
      break;
  }
  return { height: h, width: w, borderWidth: bw };
};

export const SpinnerComponent = (props?: SpinnerComponentProps) => {
  let dimensions = getSpinnerDimensions("medium");

  if (!!props) {
    if (!!props.size) {
      dimensions = getSpinnerDimensions(props.size);
    } else if (!!props.height && !!props.width && !!props.borderWidth) {
      dimensions.height = props.height;
      dimensions.width = props.width;
      dimensions.borderWidth = props.borderWidth;
    }
  }

  return (
    <Spinner
      styles={{
        circle: {
          height: dimensions.height,
          width: dimensions.width,
          borderWidth: dimensions.borderWidth,
        },
      }}
    />
  );
};
