import { useDataStoreContext } from "src/store/dataStoreContext";
import { surveyService } from "src/services/surveyService";
import { QuestionComponent } from "src/components/QuestionComponent";
import styled from "@emotion/styled";
import React, { useEffect, useState } from "react";
import { MessageBar, MessageBarType, PrimaryButton, Stack } from "@fluentui/react";
import { useHistory } from "react-router-dom";
import { Text } from "@fluentui/react";
import { tractEvent } from "src/telemetry/appInsights";
import { SpinnerComponent } from "src/core/components/SpinnerComponent";
import { PartnerSurveyHeaderColor, CustomerSurveyHeaderColor } from "src/core/colors";
import _ from "lodash";
import { validationService } from "src/services/validationService";
import { SurveyQuestion } from "src/types/surveyTypes";

const SurveyContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const SurveyHeaderTextContainer = styled(Stack)<{ backgroundColor: string }>`
  background-color: ${(props) => props.backgroundColor};
  color: #ffffff;
  height: 282px;
  justify-content: center;
  text-align: left;
`;

const SurveyHeaderText = styled(Text)`
  color: #ffffff;
  font-size: 38px;
  font-weight: 400;
`;

const SurveySubHeaderText = styled(Text)`
  color: #ffffff;
  font-size: 15px;
  font-weight: 400;
`;

const RequiredHeaderText = styled.div`
  margin-top: 8px;
`;

const SurveyQuestionsGrid = styled.div`
  display: grid;
  grid-template-columns: 10% 80% 10%;
`;

const SurveyQuestionsColumn = styled.div`
  grid-row-start: 2;
  grid-row-end: 2;
  grid-column-start: 2;
  grid-column-end: 2;
`;

const SurveyItem = styled.div`
  margin-top: 48px;
`;

const MessageArea = styled(MessageBar)`
  margin-top: 32px;
`;

const SubmitButton = styled(PrimaryButton)`
  margin-top: 32px;
`;

const RequiredField = styled.div`
  color: #ff0000;
  display: inline;
  margin-right: 4px;
`;

const RequiredFieldMissingMessage = "Required fields are missing";
const BlanketErrorMessage = "Please fix errors.";

export const SurveyComponent = () => {
  const { dataStore, dataStoreDispatch } = useDataStoreContext();
  const { requestId } = dataStore;
  const { survey, surveyResponse } = dataStore;
  const [showProgress, setShowProgres] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const history = useHistory();

  const validateAnswerString = (question: SurveyQuestion): boolean => {
    const answer = surveyResponse.userResponse.find((ur) => ur.questionId === question.id);

    return question.type === "Text" || question.type === "LargeText"
      ? !!answer && !!answer.answerText && answer.answerText.trim().length > 0
      : !!answer;
  };

  const hasMisssingFields = survey.questions.some((q) => {
    if (q.type === "Likert") {
      const hasSubQuestionResponses = q.subQuestions?.every((sb) => !!surveyResponse.userResponse.find((ur) => ur.questionId === sb.id));
      return q.isRequired && !hasSubQuestionResponses;
    } else {
      return q.isRequired && !validateAnswerString(q);
    }
  });

  const hasValidationErrors = survey.questions.some((q) => {
    if (!_.isEmpty(q.validationType)) {
      const txtResponse = surveyResponse.userResponse.find((ur) => ur.questionId === q.id);
      const rsp = txtResponse?.answerText;

      if (!q.isRequired && !rsp) {
        return false;
      }

      let validationResult;

      if (q.type === "Text") {
        validationResult = validationService.validateText(q, rsp);
      } else if (q.type === "Date") {
        validationResult = validationService.validateDate(q, rsp);
      }
      return !!validationResult && !validationResult.valid;
    }
    return false;
  });

  useEffect(() => {
    if (showErrorMessage) {
      const needsBlanketMessage = hasMisssingFields || hasValidationErrors;
      if (hasMisssingFields) {
        setErrorMessage(RequiredFieldMissingMessage);
      } else if (hasValidationErrors) {
        setErrorMessage(BlanketErrorMessage);
      }
      setShowErrorMessage(needsBlanketMessage);
    }
  }, [surveyResponse, showErrorMessage, hasMisssingFields, hasValidationErrors]);

  const onSubmit = async () => {
    //Prevent resubmission with multiple clicks
    if (showProgress) {
      return;
    }

    if (hasMisssingFields) {
      setErrorMessage(RequiredFieldMissingMessage);
      setShowErrorMessage(true);
      return;
    }

    if (hasValidationErrors) {
      setErrorMessage(BlanketErrorMessage);
      setShowErrorMessage(true);
      return;
    }

    setShowProgres(true);
    const response = await surveyService.submitSurvey(surveyResponse);
    setShowProgres(false);

    tractEvent("SURVEY_SUBMITTED", {
      requestId: requestId,
      programType: survey.programKind,
      surveyType: survey.type,
      workshopType: survey.workshopKind,
    });

    if (!response.hasError) {
      setErrorMessage("");
      setShowErrorMessage(false);

      // clear user responses
      dataStoreDispatch({
        kind: "SET_SURVEY_RESPONSE",
        payload: {
          requestId: requestId,
          surveyId: survey.id,
          surveyTypeId: survey.typeId,
          workshopTypeId: survey.workshopTypeId,
          programTypeId: survey.programTypeId,
          userResponse: [],
        },
      });
      history.push({ pathname: "/thank-you", state: { message: "Survey submitted successfully" } });
    } else {
      setErrorMessage("An error occured, could not submit the survey.");
      setShowErrorMessage(true);
    }
  };

  const onDismiss = () => {
    setShowErrorMessage(false);
    setErrorMessage("");
  };

  const headerBackground = survey.type === "Customer" ? CustomerSurveyHeaderColor : PartnerSurveyHeaderColor;

  const displayName = !!survey ? (!_.isEmpty(survey.displayName) ? survey.displayName : survey.name) : "";

  return (
    <SurveyContainer>
      <SurveyHeaderTextContainer horizontalAlign="center" tokens={{ childrenGap: 16, padding: 8 }} backgroundColor={headerBackground}>
        <SurveyHeaderText variant={"xxLarge"}>{displayName}</SurveyHeaderText>
        <SurveySubHeaderText variant={"xLarge"}>{survey?.description}</SurveySubHeaderText>
        <SurveySubHeaderText variant={"xLarge"}>
          Note: Information in survey is maintained within Microsoft and is not shared externally or used for marketing purposes
        </SurveySubHeaderText>
      </SurveyHeaderTextContainer>
      <SurveyQuestionsGrid>
        <SurveyQuestionsColumn>
          <RequiredHeaderText>
            <Text variant={"mediumPlus"}>
              <RequiredField>*</RequiredField>
              Required
            </Text>
          </RequiredHeaderText>
          {survey?.questions.map((q, idx) => (
            <SurveyItem key={q.id}>
              <QuestionComponent displaySequenceOrder={idx + 1} question={q} />
            </SurveyItem>
          ))}
          {showErrorMessage && (
            <MessageArea messageBarType={MessageBarType.error} onDismiss={onDismiss} dismissButtonAriaLabel="Close">
              {errorMessage}
            </MessageArea>
          )}
          <SubmitButton onClick={onSubmit} disabled={showProgress}>
            {showProgress && <SpinnerComponent size="small" />}
            {!showProgress && "Submit"}
          </SubmitButton>
        </SurveyQuestionsColumn>
      </SurveyQuestionsGrid>
    </SurveyContainer>
  );
};
